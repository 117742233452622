/* Get rid of all this */
.extension-menu-list {
	max-height: 300px;
	left: 10%;
	display: block;
	overflow-y: auto;
}

.filter-item {
	position: relative;
	border-bottom: 1px solid lightgray;
}

.filter-item input {
	width: 100%;
	padding: 7px 5px;
	border: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	font-size: 16px;
}

.filter-item input:focus {
	outline: none;
}

.filter-item .search-icon {
	position: absolute;
	right: 10px;
	top: 6px;
	color: gray;
	cursor: default;
	width: 23px;
}

.extension-menu-options {
	position: relative;
	width: 100%;
	background-color: #fff;
	border: 1px solid lightgray;
	border-top: none;
}

.extension-menu-options > button.logout-button {
	float: right;
}

.extension-menu-options > button.control-panel-button {
	float: left;
}

.hidden {
	display: none !important;
}

.btn-secondary.dropdown-toggle.delete-dropdown-toggle {
    position: absolute;
    right: 0;
    top: 13px;
    font-size: 14px !important;
    padding: 0px;
    background-color: transparent !important;
    border: none;
    color: #888 !important;
    box-shadow: none !important;
}

.btn-secondary.dropdown-toggle.delete-dropdown-toggle:active, .btn-secondary.dropdown-toggle.delete-dropdown-toggle:focus {
    background-color: transparent !important;
    outline: none;
}

.delete-dropdown-toggle > .Ripple.is-reppling {
    animation: none !important;
}

.header-bar .options-menu-toggle-button .dropdown-menu.loading .dropdown-extension-item {
	opacity: 0.7;
	cursor: progress;
	background: #eaeaea;
}

.dropdown-menu .dropdown-extension-item {
	padding: 7px 5px;
	font-size: 14px;
	display: flex;
}

.dropdown-menu .dropdown-extension-item .item-labels {
	margin-right: 8px;
}

.header-bar .options-menu-toggle-button .current-extension-dropdown button {
	font-size: 13px;
}

.dropdown-menu .dropdown-extension-item.active {
	color: green;
	font-weight: bold;
}

.dropdown-menu .dropdown-extension-item:not(:last-child) {
	border-bottom: 1px solid lightgray;
}

.dropdown-menu .dropdown-extension-item:not(.active):hover {
	background: #eaeaea;
}

.dropdown-menu .dropdown-extension-item.active:hover {
	cursor: default;
}

.dropdown-menu.show {
	padding: 0;
}

.extension-caller-id{
	color: #0079ff;
	font-size: 16px;
}
